import React from "react"
import { Loading } from "../components/Loading";
import * as THREE from 'three';
import SEO from "./SEO";

export default function Layout({ children }) {

    const [loading, setLoading] = React.useState(true);

    const refContainer = React.useRef(null);

    React.useEffect(() => {
        const SCREEN_WIDTH = window.innerWidth, SCREEN_HEIGHT = window.innerHeight, r = 450
        let mouseY = 0, windowHalfY = window.innerHeight / 2, camera, scene, renderer;
        
        init();
        animate();
        
        function init() {
        
            camera = new THREE.PerspectiveCamera(100, SCREEN_WIDTH / SCREEN_HEIGHT, 1, 500);
            camera.position.z = 1000;
        
            scene = new THREE.Scene();
        
            const parameters = [[1, 0x2A00C2, 0.5], [1.25, 0x3100DB, 0.8], [3.0, 0xaaaaaa, 0.75], [0.5, 0xffffff, 0.5 ], [4.5, 0xffffff, 0.25], [5.5, 0xffffff, 0.125]];
            const geometry = createGeometry();
        
            for (let i = 0; i < parameters.length; ++i) {
        
                const p = parameters[ i ];
                const material = new THREE.LineBasicMaterial({ color: p[1], opacity: p[2] });
        
                const line = new THREE.LineSegments(geometry, material);
                line.scale.x = line.scale.y = line.scale.z = p[0];
                scene.add(line);
        
            }
        
            const canvas = document.querySelector('#c');
            renderer = new THREE.WebGLRenderer({ canvas });
        
            // renderer = new THREE.WebGLRenderer( { antialias: true } );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );
            // document.body.appendChild( renderer.domElement );
        
            document.body.style.touchAction = 'none';
            // document.body.addEventListener( 'pointermove', onPointerMove );
        
            window.addEventListener( 'resize', onWindowResize );
        }
        
        function createGeometry() {
        
            const geometry = new THREE.BufferGeometry();
            const vertices = [];
        
            const vertex = new THREE.Vector3();
        
            for ( let i = 0; i < 30000; i ++ ) {
        
                vertex.x = Math.random() * 2 - 1.55;
                vertex.y = Math.random() * 2 - 1.55;
                vertex.z = Math.random() * 2 - 1.55;
        
                vertex.normalize();
                vertex.multiplyScalar(r);
        
                vertices.push( vertex.x, vertex.y, vertex.z );
                vertex.multiplyScalar( Math.random() * 0.009 + 1 );
                vertices.push( vertex.x, vertex.y, vertex.z );
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            return geometry;
        }
        
        function onWindowResize() {
            windowHalfY = window.innerHeight / 2;
        
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        
            renderer.setSize( window.innerWidth, window.innerHeight );
        
        }
        
        // function onPointerMove( event ) {
        //     if ( event.isPrimary === false ) return;
        //     mouseY = event.clientY - windowHalfY;
        // }
        
        function animate() {
            requestAnimationFrame( animate );
            render();
        }
        
        function render() {
            camera.position.y += ( - mouseY + 200 - camera.position.y ) * .05;
            camera.lookAt( scene.position );
            camera.position.z = 10;
        
            renderer.render(scene, camera);
        
            const time = Date.now() * 0.00004;
        
            for ( let i = 0; i < scene.children.length; i ++ ) {
        
                const object = scene.children[i];
        
                if (object.isLine) {
                    object.rotation.z = time * (i < 4 ? (i + 1) : - (i + 1));
                }
        
            }
        
        }  

        setTimeout(() => {
        setLoading(false)
        }, 1000)
    }, [])

  return (
    <>
        <SEO
          title={'Bartłomiej Ryba | Software Engineer'}
        />
        {loading && <Loading />}

        <div id="bg-container">
            <canvas id="c" ref={refContainer}></canvas>
        </div>

        <div id="content-container">
            {children}

        </div>
    </>
  )
}